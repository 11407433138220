import React from 'react';
import {graphql} from 'gatsby';
import {Container, Row, Col} from 'react-bootstrap';

import {MDXRenderer} from 'gatsby-plugin-mdx';
import {MDXProvider} from '@mdx-js/react';
import PropTypes from 'prop-types';
import Layout from '../layout';
import SEO from '../seo';
import SectionHero from '../section-hero';
import SectionSubscribe from '../section-subscribe';

import FacebookPixel from '../pixels/onLoad/FacebookPixel';
import DGLVPixel from '../pixels/onLoad/DGLVPixel';
import DGLVButton from '../pixels/onClick/DGLVButton';

const components = {FacebookPixel, DGLVPixel, DGLVButton};

const AnnouncementLayout = ({data}) => {
  const post = data.mdx;

  return (
    <Layout className="post">
      <SEO title={post.frontmatter.title} />
      <SectionHero className="post__hero" fileName={post.frontmatter.heroImage}>
        <Container className="post__hero__container">
          <Row className="justify-content-center">
            <Col xs={10}>
              <h1 className="post__title">{post.frontmatter.title}</h1>
            </Col>
          </Row>
        </Container>
      </SectionHero>
      <Container className="post__content__container">
        <Row className="justify-content-center">
          <Col xs={12} lg={10} className="post__content">
            <MDXProvider components={components}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </Col>
        </Row>
      </Container>
      <SectionSubscribe />
    </Layout>
  );
};

AnnouncementLayout.propTypes = {
  data: PropTypes.any,
};

export default AnnouncementLayout;

export const query = graphql`
  query($slug: String!) {
    mdx(slug: {eq: $slug}) {
      body
      frontmatter {
        heroImage
        title
      }
    }
  }
`;
